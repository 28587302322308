<template>
  <div class="">
    <v-card :loading="loading" class="shadow-off" :disabled="loading" loader-height="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn small color="btnColor" class="mr-1 white--text" @click="addForm()" v-if="$nova.hasRight(right, 'add')">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>New</span>
          </v-btn>
          <bulkPBCUpload @reload="refreshData()" v-if="$nova.hasRight(right, 'add')"/>
          <v-btn class="shadow-off" text @click="refreshData()" small icon>
             <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
         <div class="d-flex">
             <v-row align="center">
              <v-col
                class="d-flex"
                cols="12"
                sm="4"
              >
            <v-select
                v-model="locationfilter"
                :items="location_details" 
                outlined 
                dense 
                item-text="name"
                item-value="_id"
                :label="$store.state.LocationHeader ? 'Apply'+' '+$store.state.LocationHeader  :'Apply Location' "
                ></v-select>
              </v-col>
              <v-btn small color="btnColor" class="mr-1 mb-8 white--text" @click="locationFilterFun(locationfilter)" v-if="$nova.hasRight(right, 'add')">
                    <v-icon class="mr-1">mdi-search-web</v-icon>
                    <span>Apply {{ $store.state.LocationHeader ? $store.state.LocationHeader  :'Location' }} filter</span>
                  </v-btn>
                  <v-btn small color="btnColor" class="mr-1 mb-8 white--text" @click="refreshData()" v-if="$nova.hasRight(right, 'add')">
                    <v-icon class="mr-1">mdi-close-circle </v-icon>
                    <span>Clear filter</span>
                  </v-btn>
             </v-row>
          </div> 
       
       
        <DataTable
          :headers="headers"
          :items="formatedData"
          :enableslot="['action', 'tasklist', 'urgent', 'type','duedate']"
          :loading="loading"
          :bgColor="true"
        >
          <template v-slot:type="{ item }">
            <div class="text-center" style="max-width: 45px">
              <v-tooltip
                right
                content-class="tooltip-right"
                v-if="item.type === 'text'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-text-short</v-icon>
                </template>
                <span>Text Request</span>
              </v-tooltip>
              <v-tooltip
                right
                content-class="tooltip-right"
                v-else-if="item.type === 'fileupload'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-file-upload</v-icon>
                </template>
                <span>File Upload Request</span>
              </v-tooltip>
              <v-tooltip
                right
                content-class="tooltip-right"
                v-else-if="item.type === 'fileuploadtolink'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-upload-network</v-icon>
                </template>
                <span>File Upload to a link Request</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:duedate="{ item }">

            <div v-if="new Date(item.duedate || '')">
              <v-tooltip bottom content-class="tooltip-bottom" v-if="item.stage === 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  color="error white--text" v-bind="attrs" v-on="on" v-if="((new Date(item.duedate || '') - new Date(item.reviewed_on)))/(1000*24*60*60) < -1">
                    {{Math.ceil((new Date(item.reviewed_on) - new Date(item.duedate || ''))/(1000*24*60*60))}}
                  </v-chip>
                </template>
                <span>Delay</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(item.duedate || '') - new Date()) >= -1">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  color="info white--text" v-bind="attrs" v-on="on">
                    {{Math.ceil((new Date(item.duedate || '') - new Date())/(1000*24*60*60))}}
                  </v-chip>
                </template>
                <span>Days Remaining</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(item.duedate || '') - new Date()) < -1">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  color="error white--text" v-bind="attrs" v-on="on">
                    {{Math.ceil((new Date() - new Date(item.duedate || ''))/(1000*24*60*60))}}
                  </v-chip>
                    </template>
                <span>Days Overdue</span>
              </v-tooltip>
            </div>  
          </template>
          <template v-slot:urgent="{ item }">
            <v-chip small v-if="item.urgent" label color="error">Yes</v-chip>
            <v-chip small v-else label>No</v-chip>
          </template>
          <template v-slot:tasklist="{ item }">
           
            <div class="" v-if="(item.tasklist || []).length > 0">
              <v-menu
                offset-x left open-on-hover
                v-for="(v,k) in (item.tasklist || [])"
                :key="k"
                :max-width="400"
                :max-height="100"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="pl-2 mb-1 mr-1"
                  >
                    <v-icon>mdi-tag</v-icon>
                    <span class="pl-1">{{(getTaskName(v.checklist_id)).ref}}</span>
                  </v-chip>
                </template>
                <v-card class="shadow-off">
                  <v-card-text class="border-on">{{ (getTaskName(v.checklist_id)).description}}
                    <v-chip
                      class="ma-2 border-on radius-all"
                      
                      label
                      small
                      
                    >
                      <v-icon left>
                        mdi-map-marker
                      </v-icon>
                     {{getLocationName(v.location)}}
                    </v-chip>
                     </v-card-text>
                  
                </v-card>
              </v-menu>
            </div>
            <div class="" v-else>NA</div>
          </template>
          <template v-slot:action="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="addEditForm(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'disable')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="item.status && $nova.hasRight(right, 'read')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error"
                  v-bind="attrs"
                  v-on="on" >mdi-close-circle</v-icon>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="
                  !item.status && $nova.hasRight(right, 'enabledisable')
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'enable')"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="!item.status && $nova.hasRight(right, 'read')"
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="success"
                    v-bind="attrs"
                    v-on="on" >mdi-check-circle</v-icon>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </div>
          </template>
        </DataTable>

        <!-- <v-data-table
          dense
          :headers="tableheader"
          :items="listdata"
          :items-per-page="-1"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.checklist_id`]="{ item }">
            <div class="" v-if="(item.checklist_id_detail || []).length > 0">
              <v-tooltip
                v-for="(v, k) in item.checklist_id_detail"
                :key="k"
                bottom
                content-class="tooltip-bottom"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="pl-2 mb-1 mr-1"
                  >
                    <v-icon>mdi-tag</v-icon>
                    <span class="pl-1">{{ v.ref }}</span>
                  </v-chip>
                </template>
                <span>{{ v.description }}</span>
              </v-tooltip>
            </div>
            <div class="" v-else>NA</div>
          </template>
          <template v-slot:[`item.urgent`]="{ item }">
            <v-chip small v-if="item.urgent" label color="error">Yes</v-chip>
            <v-chip small v-else label>No</v-chip>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="text-center" style="max-width: 45px">
              <v-tooltip
                right
                content-class="tooltip-right"
                v-if="item.type === 'text'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-text-short</v-icon>
                </template>
                <span>Text Request</span>
              </v-tooltip>
              <v-tooltip
                right
                content-class="tooltip-right"
                v-else-if="item.type === 'fileupload'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-file-upload</v-icon>
                </template>
                <span>File Upload Request</span>
              </v-tooltip>
              <v-tooltip
                right
                content-class="tooltip-right"
                v-else-if="item.type === 'fileuploadtolink'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-upload-network</v-icon>
                </template>
                <span>File Upload to a link Request</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="addEditForm(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'disable')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="
                  !item.status && $nova.hasRight(right, 'enabledisable')
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'enable')"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table> -->
      </v-card-text>
    </v-card>

    <lb-dailogboxnew
      v-model="addeditdialog"
      :heading="addeditdata._id ? 'Update' : 'Add New'"
      width="600"
      :loading="addeditloading"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model.trim="addeditdata.description"
                label="Description*"
                :error="addeditdataerror.description"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model.trim="addeditdata.info_owner_name"
                label="Information Owner Name*"
                :error="addeditdataerror.info_owner_name"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model.trim="addeditdata.category"
                label="Category"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model.trim="addeditdata.info_owner_email"
                label="Information Owner Email*"
                :error="addeditdataerror.info_owner_email"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="d-flex align-center mb-2 mt-2">
              <span class="mr-2 font-weight-bold">Tasklist:</span><v-btn class="shadow-off" small text @click="addedittaskdialog = true; selectchecklist_id = '';selectlocation_id = '';"><v-icon class="mr-1">mdi-plus</v-icon>Add Taskist</v-btn>
            </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
            <v-simple-table dense class="mb-4 FC-Table" v-if="(tasklistlocation || []).length > 0">
              <thead>
                <tr>
                  <th scope="Name">Ref</th>
                  <th scope="Name">Tasklist</th>
                  <th scope="columns">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</th>
                  <th scope="Action">Remove</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item,index in (tasklistlocation || [])">
                  <tr :key="index">
                    <td>{{(getTaskName(item.checklist_id)).ref}}</td>
                    <td>{{(getTaskName(item.checklist_id)).description}}</td>
                    <td>{{getLocationName(item.location)}}</td>
                    <td>
                      <v-icon class="ma-1" color="error" @click="removetasklist(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>
                
              </tbody>
            </v-simple-table>
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown
                v-model.trim="addeditdata.type"
                label="Type*"
                :items="informationtypes"
                :error="addeditdataerror.type"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <v-checkbox
                v-model.trim="addeditdata.urgent"
                dense
                label="Urgent"
                :value="false"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-number v-model.trim="addeditdata.duedate" label="Due Date" :error="addeditdataerror.duedate" min="0"/>
              <!-- <lb-date v-model="addeditdata.duedate" label="Due Date" :error="addeditdataerror.duedate" :min="$nova.formatDateISO(new Date())"/> -->
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="addEditItem()">
          <span v-if="addeditdata._id">Update</span>
          <span v-else>Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>

     <lb-dailogboxnew
      v-model="addedittaskdialog"
      :heading="addeditdata._id ? 'Update' :  $store.state.LocationHeader ? $store.state.LocationHeader  +' Based Tasklist' :'Location' +' Based Tasklist'"
      width="600"
      :loading="addedittaskloading"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                v-model="selectchecklist_id"
                label="Tasklist*"
                itemtext="description"
                itemvalue="_id"
                :items="checklist"
                @change="selectLocation(selectchecklist_id)"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1" v-if="selectchecklist_id">
              <lb-dropdown
                v-model="selectlocation_id"
                :label="$store.state.LocationHeader ? $store.state.LocationHeader+'*'  :'Location*'"
                itemtext="name"
                itemvalue="_id"
                :items="tasklocation_details"
              />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="addEditTaskItem()">
          <span>Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>
  </div>
</template>

<script>
import bulkPBCUpload from "../../views/Components/BulkUploadInformationManager.vue";
import DataTable from "../../../../views/common/DataTable.vue";

export default {
  name: "financialclose_master_informationmanager",
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_informationmanager",
      // generateddescription: "",
      tableheader: [
        { text: "Type", value: "type", sortable: false },
        { text: "Category", value: "category" },
        { text: "Description", value: "description" },
        { text: "Information Owner Name", value: "info_owner_name" },
        { text: "Due Date", value: "duedate" },
        { text: "Information Owner Email", value: "info_owner_email" },
        { text: "Urgent", value: "urgent" },
        { text: "Tasklist", value: "checklist_id" },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      addeditdata: {},
      addeditdataerror: {},
      informationtypes: [
        { value: "text", name: "Text" },
        { value: "fileupload", name: "File Upload" },
      ],
      addeditdialog: false,
      checklist: [],
      addeditloading: false,
      addedittaskloading: false,
      addedittaskdialog:false,
      loading: false,
      listdata: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "category",
          text: "Category",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },

        {
          value: "description",
          text: "Description",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "info_owner_name",
          text: "Information Owner Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "duedate",
          text: "Due Days",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "info_owner_email",
          text: "Information Owner Email",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "urgent",
          text: "Urgent",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
          filterList: [
            { name: "Yes", value: true },
            { name: "No", value: false },
          ],
        },
        {
          value: "tasklist",
          text: "Tasklist",
          lock: true,
          visible: false,
        },

        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      formatedData: [],
      selectchecklist_id:'',
      selectlocation_id:'',
      tasklistlocation:[],
      addedittaskdataerror:{},
      location_details:[],
      tasklocation_details:[],
      locationfilter:[],
    };
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  components: {
    bulkPBCUpload,
    DataTable,
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          type: item.type,
          category: item.category?.trim(),
          description: item.description?.trim(),
          duedate:item.duedate,
          info_owner_email: item.info_owner_email?.trim(),
          info_owner_name: item.info_owner_name?.trim(),
          urgent: item.urgent,
          tasklist: item.checklist_id,
          checklist_id_detail: item.checklist_id_detail,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    init() {
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.locationfilter = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$store.commit("setProjectName", "");
      return this.axios
        .post("/v2/financialclose/masters/informationmanager/formData")
        .then((dt) => {
          if (dt.data.status === "success") {
            let formdt = dt.data.data[0] || {};
            this.checklist = formdt.checklist || [];
            return this.axios.post(
              "/v2/financialclose/masters/informationmanager/get"
            );
          } else throw new Error("Error reading form data");
        })
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success") {
            this.listdata = dt.data.data;
            this.formateData();
          } else throw new Error(dt.data.message);
        }).then(() => {
           this.axios
              .post("/v2/financialclose/location/get")
              .then((ele) => {
                this.location_details = ele.data.data;
              })
              .catch((err) => {
                console.log(err);
              });
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addForm(){
      this.addeditdata = {};
      this.addeditdataerror = {};
      this.addeditdialog = true;
      this.tasklistlocation = [];
    },
    addEditForm(dt = {}) {
      this.addeditdata = { ...dt };
      
      this.tasklistlocation = JSON.parse(JSON.stringify(this.addeditdata.tasklist));
      this.addeditdataerror = {};
      this.addeditdialog = true;
      //tasklistlocation
    },
    addEditItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/financialclose/masters/informationmanager/add";
      if (this.addeditdata._id) {
        type = "edit";
        url =
          "/v2/financialclose/masters/informationmanager/edit/" +
          this.addeditdata._id;
      }
      this.addeditdata.checklist_id = this.tasklistlocation;
      let ndt = { ...this.addeditdata };
      delete ndt._id;
      this.axios
        .post(url, { data: ndt })
        .then((dt) => {
          if (dt.data.status === "success") {
            if (type === "add")
              this.$store.commit("sbSuccess", "Tasklist item added");
            else this.$store.commit("sbSuccess", "Tasklist item updated");
            this.addeditdialog = false;
            this.refreshData();
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0)
                this.addeditdataerror = dt.data.data[0].index0;
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addeditloading = false;
        });
    },
    enableDisableAccess(id, action) {
      this.loading = true;
      let url = "/v2/financialclose/masters/informationmanager/disable/";
      if (action === "enable") {
        url = "/v2/financialclose/masters/informationmanager/enable/";
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if (action === "enable") {
              this.$store.commit("sbSuccess", "Item enabled");
              // this.listdata[k].status = true;
            } else {
              this.$store.commit("sbSuccess", "Item disabled");
              // this.listdata[k].status = false;
            }
            this.refreshData();
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addEditTaskItem(){
      if (this.selectchecklist_id && this.selectlocation_id) {
        let obj = {
          checklist_id:this.selectchecklist_id,
          location:this.selectlocation_id
        }
        this.tasklistlocation.push(obj);
        this.addedittaskdialog = false;
      } else {
         this.$store.commit("sbError", "Taskllist and location Required");
      }
    },
    getLocationName(locationId) {
      const location = this.location_details.find(
        (loc) => loc._id === locationId
      );
      return location ? location.name : "Unknown";
    },
    getTaskName(taskId) {
      const task = this.checklist.find((task) => task._id === taskId);
      return task ? task : "Unknown";
    },
     removetasklist(item){
      let indexItem = this.tasklistlocation.indexOf(item);
      this.tasklistlocation.splice(indexItem, 1);
    },
    selectLocation(Id){
      let taskLoc = '';
      if (Id) {
         taskLoc = this.checklist.find((x) => x._id === Id);
      }
      this.tasklocation_details = taskLoc.location;
    },
    locationFilterFun(locationid){
      this.loading = true;
      if (locationid) {
        this.axios
          .post("/v2/financialclose/masters/informationmanager/getinfomationwithfilter/"+locationid)
          .then((ele) => {
             if (ele.data.status === "success") {
              this.listdata = ele.data.data;
              this.formateData();
             }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
          this.loading = false;
        });
      }
    },
  },
  watch: {},
};
</script>
